import React, { useState } from "react"
import styled from 'styled-components'
//import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Text = styled.p``

const Label = styled.label`
  margin-block-end: 8px;
`

const Input = styled.input`
  color: darkred;
  font-weight: 500;
  padding: 8px 12px;
  margin-inline: 8px;
`

const Bold = styled.strong`

`

const IndexPage = () => {
  const [tbw, setTBW] = useState(0)

  const inputHandler = (e) => {
    const { value: rawLbas = 0 } = e?.target || {}
    const lbas = Math.max(rawLbas, 0)
    const bytes = lbas * 512 //sector size
    setTBW(bytes / 1000 ** 4)
  }
  
  return (
  <Layout title="TBW (Terabytes Written) Calculator">
    <SEO title="TBW Calculator"/>
    <Text>To use this calculator, you need to find the S.M.A.R.T stats for your drive. When you have them, enter in the value from "Total LBAs Written".</Text>

    <Label>
      Total LBAs Written:
      <Input type="number" onChange={inputHandler} placeholder="Enter LBAs here!" />
    </Label>
      {tbw && tbw > 0 ? <Text><Bold>{tbw.toFixed(2)}</Bold> terabytes written!</Text> : null}
  </Layout>
)
  }

export default IndexPage
